import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'

/**
 * Base
 */
// Debug
// const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Models
 */
const gltfLoader = new GLTFLoader()

// MeshStandardMaterial
const material = new THREE.MeshStandardMaterial()
material.metalness = .7
material.roughness = .2
material.color = 0xffc800
material.colorSpace = THREE.SRGBColorSpace

// gui.add(material, 'metalness').min(0).max(1).step(.0001)
// gui.add(material, 'roughness').min(0).max(1).step(.0001)

gltfLoader.load(
    '/models/XmasTree/glTF/XmasTree.gltf',
    (gltf) =>
    {
        const group = new THREE.Group()
        const children = [...gltf.scene.children]

        for (const child of children) {
            child.castShadow = true
            group.add(child)
        }
        scene.add(group)
        console.log(group)
        group.position.y = 1
    }
)



/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(2.5, 2.5),
    new THREE.MeshStandardMaterial({
        color: '#aaaaaa',
        metalness: 0,
        roughness: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
floor.position.y = -0.40
scene.add(floor)
console.log(floor)



/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 3)
scene.add(ambientLight)

const pointLight1 = new THREE.PointLight(0xffffff, 300)
pointLight1.castShadow = true
pointLight1.position.x = 6
pointLight1.position.y = 6
pointLight1.position.z = 6

const pointLight2 = new THREE.PointLight(0xffffff, 300)
pointLight2.castShadow = true
pointLight2.position.x = -6
pointLight2.position.y = 6
pointLight2.position.z = -6
scene.add(pointLight1, pointLight2)

// gui.add(ambientLight, 'intensity').min(0).max(3).step(.1).name('Ambient light intensity')
// gui.add(pointLight1, 'intensity').min(1).max(500).step(1).name('Point light intensity')
// gui.add(pointLight2, 'intensity').min(1).max(500).step(1).name('Point light intensity')

/**
 * Environment map
 */
// const rgbeLoader = new RGBELoader()
// rgbeLoader.load('./textures/environmentMap/iwf_2k.hdr', (environmentMap) =>
// {
//     environmentMap.mapping = THREE.EquirectangularReflectionMapping

//     scene.background = environmentMap
//     scene.environment = environmentMap
// })

// Axes helper
// const axesHelper = new THREE.AxesHelper(3)
// scene.add(axesHelper)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(30, sizes.width / sizes.height, 0.1, 100)
camera.position.set(2, 3, 8)
scene.add(camera)

// const camera = new THREE.OrthographicCamera( width / - 2, width / 2, height / 2, height / - 2, 1, 1000 );
// camera.position.set(0, 0, -10)
// scene.add( camera );

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0.75, 0)
controls.enableDamping = true
controls.maxDistance = 12
controls.minDistance = 4
controls.maxPolarAngle = 0.5 * Math.PI
controls.minPolarAngle = 0.25 * Math.PI
controls.autoRotate = true
controls.autoRotateSpeed = 2.0

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()